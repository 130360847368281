<template>
  <div>
    <b-tabs pills>
      <b-tab active>
        <template #title>
          <feather-icon icon="DiscIcon" />
          <span>Araç Kartı Arama</span>
        </template>
        <car-search />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Müşteri Kartı Arama</span>
        </template>
        <customer-search />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import {
  BTabs,
  BTab,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import CustomerSearch from '@/views/Service/Search/CustomerSearch.vue'
import CarSearch from '@/views/Service/Search/CarSearch.vue'

export default {
  name: 'Search',
  components: {
    BTabs,
    BTab,
    CustomerSearch,
    CarSearch,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
}
</script>
