<template>
  <b-button
    variant="danger"
    :disabled="customer.submitStatus || (customer.kvkk !== 1 && kvkkRequire === true)"
    @click="saveAction"
  >
    Kaydet
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  name: 'SaveButton',
  components: {
    BButton,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: {
    actionMethods: {
      type: Function,
      required: true,
    },
    kvkkRequire: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
    saveStatus() {
      return this.$store.getters['customers/getCustomerSaveStatus']
    },
    getPhoneControl() {
      return this.$store.getters['customers/getPhoneControl']
    },
  },
  methods: {
    saveAction() {
      this.actionMethods()
    },
  },
}
</script>

<style scoped>

</style>
