<template>
  <b-row>
    <b-col>
      <b-form-group
        label="Adı"
        label-for="name"
      >
        <validation-provider
          #default="{ errors }"
          name="Adı"
          rules="required"
        >
          <b-form-input
            id="name"
            v-model="customer.name1"
            placeholder="adı"
            :readonly="isReadonly || customer.logo_id !== null"
            @keydown="check"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        label="Soyadı"
        label-for="surname1"
      >
        <validation-provider
          #default="{ errors }"
          name="Soyadı"
          rules="required"
        >
          <b-form-input
            id="surname1"
            v-model="customer.surname1"
            placeholder="Soyadı"
            :readonly="isReadonly ||customer.logo_id !==null"

            @keydown="check"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol,
  BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, localize } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'NameSurname',
  components: {
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    ValidationProvider,
  },
  props: {
    isReadonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      locale: 'tr',
      inputRegex: /^[a-zA-ZüğışöçÜĞİŞÖÇ\s]*$/,
      required,
    }
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
  },
  created() {
    localize(this.locale)
  },
  methods: {
    check(event) {
      const bannedCharacters = /[\^+]/
      if (!this.inputRegex.test(event.key) || bannedCharacters.test(event.key)) {
        event.preventDefault()
      }
      if (bannedCharacters.test(event.key)) {
        event.preventDefault()
      }
    },
  },
}
</script>
