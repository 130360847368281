<template>
  <div>
    <b-form-group
      v-if="isRequired"
      label="E-Posta"
      label-for="email"
    >
      <validation-provider
        #default="{ errors }"
        name="E-Posta"
        rules="required|email"
      >
        <b-form-input
          id="email"
          v-model="customer.email"
          type="email"
          placeholder="E-Posta"
          :disabled="customer.logo_id !== null"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-form-group
      v-else
      label="E-Posta"
      label-for="email"
    >
      <b-form-input
        id="email"
        v-model="customer.email"
        type="email"
        placeholder="E-Posta"
        :disabled="customer.logo_id !== null"
      />
    </b-form-group>
  </div>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { required, email } from '@validations'

export default {
  name: 'Email',
  components: {
    BFormGroup,
    BFormInput,
    ValidationProvider,
  },
  props: {
    isRequired: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      locale: 'tr',
      required,
      email,
    }
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
  },
  created() {
    localize(this.locale)
  },
}
</script>

<style scoped></style>
