<template>
  <b-form-group
    label="Temas"
    label-for="meet"
  >
    <validation-provider
      #default="{ errors }"
      name="Temas"
      rules="required"
    >
      <v-select
        id="meet"
        v-model="customer.id_com_meet"
        label="title"
        :reduce="meet => meet.id"
        :options="meets"
        placeholder="Temas"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'Meet',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
    meets() {
      return this.$store.getters['meets/getMeets']
    },
  },
  created() {
    localize(this.locale)
    this.getMeets()
  },
  methods: {
    getMeets() {
      this.$store.dispatch('meets/meetsList', {
        select: ['com_meet.id AS id', 'com_meet.title AS title'],
      })
    },
  },
}
</script>

<style scoped></style>
